@import "styles/variables";


.serviceTitleWrapper {
  margin-bottom: 20px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 15px;
    padding: 4px 0;
  }
}

.serviceTitle {
  margin-bottom: 0;
}

.serviceList {
  &:not(:last-child) {
    margin-bottom: 90px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 25px;
    }
  }
}

.courseListWrapper {
  position: relative;
  .serviceList {
    margin-bottom: 90px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 25px;
    }
  }
}

.showMoreIcon {
  order: 1;
  margin-left: 5px;
}

.showMore {
  position: absolute;
  text-decoration: none;
  right: 0;
  bottom: -35px;
  &:hover .showMoreIcon {
    color: $pink;
  }
}

.hidden {
  display: none;
}

.airBnb {
  background-color: $airbnb;
}
