@import "styles/variables";
@import "styles/mixins";


.cardTitle {
  margin-bottom: 10px;
  flex-wrap: nowrap;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 10px;
  }
}

.cardTooltip {
  white-space: pre-wrap;
}

.cardName {
  svg {
    margin-top: 5px;
    transform: scale(1.3);

    @media screen and (max-width: $breakpoint-lg) {
      transform: scale(1);
      margin-top: 1px;
    }
  }
}

.text {
  margin-right: 8px;
  margin-bottom: 0;
}

.priceContent {
  color: $black;
  text-align: right;

  @media screen and (min-width: $min-breakpoint-lg) {
    min-height: 90px;
  }
  & > div {
    margin: 4px 0;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $breakpoint-lg) {
      margin: 0;
    }
  }
}

.slashPriceContainer {
  display: flex;
  justify-content: flex-end;
}

.priceText {
  margin-bottom: 0;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 12px;
  }
}

.slashPrice {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  margin-left: 16px;
  @media screen and (max-width: $breakpoint-md) {
    margin-left: 8px;
  }
}

.promotionPrice {
  color: $pink;
  text-decoration: line-through;
  margin-right: 10px;
}

.loading {
  background-color: rgba($white, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.labelField {
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 100%;
    margin-bottom: 10px;
    &:first-child {
      margin-top: 10px;
    }
  }
  & > div {
    flex: auto;
  }
}

.cardWrapper {
  background-color: $white;
  border: 1px solid $gray-border;
  margin-bottom: 10px;
  opacity: 1;
  position: relative;
  &.loadingWrapper {
    pointer-events: none;
  }

  @include transition-default(box-shadow opacity);
  &:hover {
    border-color: $teal;
    box-shadow: 0 3px 8px rgba($black, 0.15);
  }
  & > div:first-child {
    width: 100%;
  }
}

.cardBody {
  padding: 20px;
  flex: auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 8px 12px 0;
  }
}

.accommodationWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div:last-of-type {
    margin-top: auto;
  }
}

.cardTitleText > svg {
  margin-left: 8px;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 16px;
  row-gap: 4px;
  margin-bottom: 12px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 7px;
  }
}

.showOnMap {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: $breakpoint-lg) {
    position: relative;
    top: -5px;
  }
  span {
    font-size: 13px;
    line-height: 20px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  svg {
    color: $pink;
    margin-right: 8px;
    font-size: 10px;
  }
}

.promotion {
  color: $pink;
  @media screen and (max-width: $breakpoint-lg) {
    position: relative;
    top: -5px;
  }
  span {
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  svg {
    margin-right: 8px;
    font-size: 10px;
    rotate: 90deg;
  }
}

.tooltip {
  max-width: 400px;
  font-family: "Open Sans", sans-serif;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 268px;
  }
  :global(.ant-tooltip-inner) {
    padding: 8px 12px 12px;

    @media screen and (max-width: $breakpoint-lg) {
      padding: 6px 12px 11px;
    }
  }
  :global(.ant-tooltip-inner) > * {
    margin-bottom: 0;
  }
  :global(.ant-tooltip-inner),
  :global(.ant-tooltip-arrow-content) {
    color: $black;
    background-color: $white;
    box-shadow: 0 3px 8px rgba($black, 0.25);
  }
}

.typeField {
  margin-right: 20px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-right: 0;
    min-width: 74px;
  }
  & > div:first-child {
    padding-top: 2px;
    color: $black;
    min-width: 50px;
    margin-right: 20px;
    text-align: center;
    font-size: 18px;

    @media screen and (max-width: $breakpoint-lg) {
      min-width: 52px;
      margin-right: 17px;
      text-align: left;
      font-size: 20px;
    }
    svg {
      position: relative;
      &.transferPlane {
        transform: rotate(-45deg);
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
    &.accommodationCol {
      padding-right: 20px;

      @media screen and (max-width: $breakpoint-lg) {
        margin-right: 22px;
      }
    }
  }
  :global {
    .schedule {
      margin-right: 18px;
      position: relative;

      @media screen and (max-width: $breakpoint-lg) {
        margin-right: 35px;
      }
    }
  }
  &.accommodation > div:first-child {
    @media screen and (max-width: $breakpoint-lg) {
      margin-right: 25px;
    }
  }
  &.transferTypeField > div:first-child {
    min-width: 50px;
    text-align: left;

    @media screen and (max-width: $breakpoint-lg) {
      min-width: 57px;
    }
  }
}

.endDate {
  border: 1px solid $gray-border;
  height: 32px;
  line-height: 32px;
  font-size: 13px;
  color: $black;
  text-align: left;
  padding: 0 12px;
  &.disabled {
    color: $disabled-value;
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
  }
}

.numberWeeksLabel {
  max-width: 37px;
  margin-right: 31px;
  display: flex;
  align-items: center;
  & > * {
    margin-bottom: 15px;
    margin-right: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 50px;
    margin-right: 20px;
  }
}

.fieldLabel {
  margin-right: 10px;
  white-space: pre-line;
  &.firstLabel {
    width: 100%;
    max-width: 60px;

    @media screen and (max-width: $breakpoint-lg) {
      min-width: 64px;
      width: auto;
      max-width: 100%;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    min-width: 64px;
    span {
      font-size: 14px;
    }
  }
}

.fieldBlock {
  padding-top: 20px;
}

.cardPrice {
  min-width: 115px;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.buttonCol {
  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.cardMobileFooter {
  display: none;

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0;
    border-top: 1px solid $gray-border;
    button {
      margin-left: 24px;
    }
  }
}

.buttonSelect {
  width: 106px;
  padding-left: 5px;
  padding-right: 5px;

  @media screen and (max-width: $breakpoint-lg) {
    width: 125px;
  }
}

.tooltipWrapper {
  position: relative;
}

.addonLabel {
  max-width: 362px;
}

.cardTitleText {
  svg {
    margin-left: 8px;
  }
  @media screen and (max-width: $breakpoint-lg) {
    span {
      display: block;
    }
  }
  .desktopSlash {
    display: inline-block;
    margin-right: 3px;

    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }
}

.contentRow {
  flex-wrap: nowrap;
}

.black {
  color: $black;
}

.modalWrapper {
  @media screen and (max-width: $breakpoint-lg) {
    height: 100%;
    top: 0;
    margin: 0;
    max-width: 100%;
    padding: 0;
    :global {
      .ant-modal-content {
        height: 100%;
      }
      .ant-modal-body {
        height: calc(100% - 55px);
      }
    }
  }
  :global {
    .ant-modal-body {
      background-color: $gray-light-bg;
    }
    .ant-modal-header {
      display: none;
      .ant-modal-title {
        color: transparent;
      }

      @media screen and (max-width: $breakpoint-lg) {
        display: block;
      }
    }
    .ant-modal-close {
      @media screen and (max-width: $breakpoint-lg) {
        right: auto;
        left: 0;
      }
    }
  }
}

.cardTooltip:global(.ant-tooltip-placement-bottom),
.cardTooltip:global(.ant-tooltip-placement-top) {
  :global {
    .ant-tooltip-arrow {
      left: 20%;
    }
  }
}

.tooltipDescription {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.pricePerLesson {
  margin-top: 8px;
  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 0;
  }
}

.bottomBar {
  margin-top: auto;
}

.fieldWithMargin {
  margin-bottom: 16px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 10px;
  }
}

.imgWrapper {
  font-size: 0;
  min-height: 290px;

  @media screen and (max-width: $breakpoint-lg) {
    min-height: 170px;
  }
  & > div {
    width: 100%;
  }
}

.cardDescription {
  padding: 20px 0;
  white-space: pre-line;
}

.linkBlock {
  margin-top: auto;

  @media screen and (max-width: $breakpoint-lg) {
    padding-bottom: 10px;
  }
  a,
  a:link,
  a:visited {
    color: $teal;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 13px;
    }
  }
}

.slider {
  :global {
    .card-slide {
      height: 300px;

      @media screen and (max-width: $breakpoint-lg) {
        height: 212px;
      }
    
      @media screen and (max-width: $breakpoint-md) {
        height: 172px;
      }
    }
  }
}

