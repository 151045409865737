@import "styles/variables";
@import "styles/mixins";

.HighWrapper {
  padding-top: 40px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 24px;
  }
}

.CartWrapper {
  background-color: $gray-light-bg;
  margin-bottom: 48px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 24px;
  }
}

.CartContainer {
  padding-top: 48px;
  padding-bottom: 56px;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 24px 15px;
  }
}

.servicesTitleWrapper {
  padding-top: 48px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 32px;
    padding-bottom: 4px;
  }
}

.triggerWrapper {
  position: fixed;
  top: 48vh;
  top: 48lvh;
  right: 6px;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;
  button {
    padding: 7px 5px 3px;
    font-size: 12px;
    line-height: 20px;
    width: auto;
    min-width: 40px;
    margin: 6px 0;
    box-shadow: 0 2px 4px rgba($dark, 0.1), 2px 4px 6px rgba($dark, 0.07);

    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
    &:hover {
      opacity: 1;
    }
    & > div:first-child {
      width: 100%;
      & > span:first-child {
        margin: 0;
        min-width: 28px;
        position: relative;
        top: -1px;
      }
    }
  }
}

.sideNav {
  &.desktop {
    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }
  &.mobile {
    display: none;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }
  }
  .buttonText {
    display: block;
    overflow: hidden;
    max-width: 0;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    position: relative;
    top: -1px;

    @media screen and (max-width: $breakpoint-lg) {
      top: 0;
    }
    &::before {
      content: "  ";

      @media screen and (min-width: $min-breakpoint-lg) {
        white-space: pre;
      }
    }

    @include transition-default(max-width padding-left);
  }
  &:hover {
    .buttonText {
      max-width: 150px;
      flex: auto;

      @media screen and (max-width: $breakpoint-lg) {
        max-width: 0;
        flex: initial;
      }
    }
  }
}

.cart {
  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
    flex-direction: column;
    z-index: 1001;
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: $white;

    @include transition-default(left, 500ms);
    &:global(.transition-cart-enter-done) {
      left: 0%;
    }
  }
}

.snapshotsTitle {
  margin-top: 48px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 24px;
  }
}

.pageMap {
  @media screen and (max-width: $breakpoint-lg) {
    height: 250px;
  }
}

.loadingWrapper {
  min-height: calc(100vh - 530px);
  position: relative;
}
