@import "styles/variables";


.default {
  color: $teal;
}

.error {
  color: $pink;
}

.tooltip {
  max-width: 400px;
  font-family: "Open Sans", sans-serif;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 268px;
  }
  :global(.ant-tooltip-inner) {
    padding: 8px 12px 12px;

    @media screen and (max-width: $breakpoint-lg) {
      padding: 6px 12px 11px;
    }
  }
  :global(.ant-tooltip-inner) > * {
    margin-bottom: 0;
  }
  :global(.ant-tooltip-inner),
  :global(.ant-tooltip-arrow-content) {
    color: $black;
    background-color: $white;
    box-shadow: 0 3px 8px rgba($black, 0.25);
  }
}
